import React, { useRef, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import logo from "../../images/navbar/logo.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Headroom from "react-headroom";
import hamburger from "../../images/navbar/hamburger.png";
import goBack from "../../images/navbar/goBack.png";
import { isDesktop } from "../helpers/MatchMedia";
import { gsap } from "gsap";
import LogoSmallNav from "../logo/LogoSmallNav"
import useOnClickOutside from 'use-onclickoutside'

const Navbar = () => {
	const data = useStaticQuery(graphql`
		query {
			allNavbarLinksJson {
				edges {
					node {
						text
						path
					}
				}
			}
		}
	`);

	const activeStyles = {
		color: "#fca84b",
	};

	const [isOpen, switchNav] = useState(null);

	let navbar = useRef(null);
	let mobileMenu = useRef(null);
	let widthOn = `-70%`;
	let widthOff = `0%`;

	const toggle = () => {
		if (!isDesktop()) {
			switchNav((isOpen) => !isOpen);
			navbarMenuOnOff();
		}
	};

	const navbarMenuOnOff = () => {
		let globalDocument = document || {};
		let overlay = globalDocument.getElementsByClassName("overlay");
		if (!isOpen) {
			gsap.to(mobileMenu.current, { duration: 0.5, x: widthOn });
			gsap.to(navbar.current, { duration: 0, backgroundColor: "#a6a6a6" });
			// white line below navbar - problem
			gsap.to(".headroom-wrapper", { duration: 0, backgroundColor: "#a6a6a6" });
			gsap.to(overlay, { duration: 0, opacity: 1, display:'block' });
		} else {
			gsap.to(mobileMenu.current, { duration: 0.5, x: widthOff });
			gsap.to(navbar.current, { duration: 0, backgroundColor: "#fff" });
			gsap.to(".headroom-wrapper", { duration: 0, backgroundColor: "#fff" });
			gsap.to(overlay, { duration: 0, opacity: 0, display:'none' });
		}
	};

	const clickOutside = () => {
		let globalDocument = document || {};
		let overlay = globalDocument.getElementsByClassName("overlay");
		if(isOpen) {
			gsap.to(mobileMenu.current, { duration: 0.5, x: widthOff });
			gsap.to(navbar.current, { duration: 0, backgroundColor: "#fff" });
			gsap.to(".headroom-wrapper", { duration: 0, backgroundColor: "#fff" });
			gsap.to(overlay, { duration: 0, opacity: 0, display:'none' });
			switchNav(!isOpen)
		} 
	};

	useEffect(() => {
		let globalDocument = document || {};
		let documentStyle = globalDocument.getElementsByTagName("html")[0];
		isOpen
			? (documentStyle.style.overflow = "hidden")
			: (documentStyle.style = "");
	}, [isOpen]);

	useOnClickOutside(mobileMenu, () => clickOutside());

	return (
		<Headroom>
			<nav className="navbar" ref={navbar}>
				<div className="container">
					<div className="navbarItems">
						<div className="navbarLogo">
							<Link to="/">
								<img src={logo} alt="nav logo" />
							</Link>
						</div>
						<div className="navLinks" ref={mobileMenu}>
							<button className="goBackBtn" onClick={toggle}>
								<img src={goBack} alt="goBack" className="goBackImg" />
							</button>
							<ul>
								{data.allNavbarLinksJson.edges.map((item, index) => {
									return (
										<li key={index} onClick={toggle}>
											{!item.node.path.includes("#") ? (
												<Link to={item.node.path} activeStyle={activeStyles}>
													{item.node.text}
												</Link>
											) : (
												<AnchorLink
													to={item.node.path}
													title={item.node.text}
												/>
											)}
										</li>
									);
								})}
							</ul>
							<div className="smallMenuAnimation">
								<LogoSmallNav isOpen={isOpen}/>					
							</div>
						</div>
						<div className="hamburgerWrapper">
							<button className="hamburgerBtn" onClick={toggle}>
								<img
									src={hamburger}
									alt="hamburger"
									className="hamburgerImage"
								/>
							</button>
						</div>
					</div>
				</div>
			</nav>
		</Headroom>
	);
};

export default Navbar;
