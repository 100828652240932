import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

const WrapperLogo = styled.div`
	visibility: hidden;
	position: relative;
	opacity: 0.75;
	bottom: 0;
	right: 0;
`;

const LogoSmallNav = (props) => {
	let smallAnimationWrapperLogo = useRef(null);
	let smallAnimationItem1 = useRef(null);
	let smallAnimationItem2 = useRef(null);
	let smallAnimationItem3 = useRef(null);
	let smallAnimationItem4 = useRef(null);

	useEffect(() => {
		gsap.from(smallAnimationWrapperLogo.current, { duration: 0.5, autoAlpha: 0 });
		gsap.fromTo(smallAnimationItem1.current, {opacity: 0, scale: .5, y:100}, {duration: .5, opacity: 1, scale: 1, y:0, delay: .6})
		gsap.fromTo(smallAnimationItem2.current, {opacity: 0, scale: .5, x:100}, {duration: .5, opacity: 1, scale: 1, x:0, delay: .6})
		gsap.fromTo(smallAnimationItem3.current, {opacity: 0, scale: .5, y:-100}, {duration: .5, opacity: 1, scale: 1, y:0, delay: .6})
		gsap.fromTo(smallAnimationItem4.current, {opacity: 0, scale: .5, x:-100}, {duration: .5, opacity: 1, scale: 1, x:0, delay: .6})
	}, [props.isOpen]);

	return (
		<>
			<WrapperLogo ref={smallAnimationWrapperLogo} isOpen={props.isOpen}>
				<svg
					width="104"
					height="106"
					viewBox="0 0 104 106"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						ref={smallAnimationItem1}
						d="M53.4845 0H0V56.0935H53.4845V0Z"
						fill="url(#iDSmallAnimationItem1)"
					/>
					<path
						ref={smallAnimationItem2}
						d="M36.7491 105.739H16.7354C7.52945 105.739 0 98.1381 0 88.8449V68.2788C0 58.9856 7.52945 51.3848 16.7354 51.3848H36.7491C45.9551 51.3848 53.4845 58.9856 53.4845 68.2788V88.8449C53.4845 98.1381 45.9551 105.739 36.7491 105.739Z"
						fill="url(#iDSmallAnimationItem2)"
					/>
					<path
						ref={smallAnimationItem3}
						d="M64.9541 0L48.7764 16.1896V39.6607L64.9541 56.0935H88.1479L104 40.4664V16.1896L88.2496 0H64.9541Z"
						fill="url(#iDSmallAnimationItem3)"
					/>
					<path
						ref={smallAnimationItem4}
						d="M75.9535 105.739C90.963 105.739 103.131 93.5714 103.131 78.5619C103.131 63.5524 90.963 51.3848 75.9535 51.3848C60.944 51.3848 48.7764 63.5524 48.7764 78.5619C48.7764 93.5714 60.944 105.739 75.9535 105.739Z"
						fill="url(#iDSmallAnimationItem4)"
					/>
					<defs>
						<linearGradient
							id="iDSmallAnimationItem1"
							x1="2.26065e-07"
							y1="28.0473"
							x2="53.4833"
							y2="28.0473"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FBF61F" />
							<stop offset="0.1928" stopColor="#FCD433" />
							<stop offset="0.4002" stopColor="#FEB743" />
							<stop offset="0.606" stopColor="#FEA24F" />
							<stop offset="0.8076" stopColor="#FF9557" />
							<stop offset="1" stopColor="#FF9159" />
						</linearGradient>
						<linearGradient
							id="iDSmallAnimationItem2"
							x1="0"
							y1="78.5633"
							x2="53.487"
							y2="78.5633"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#CB13B2" />
							<stop offset="0.2144" stopColor="#C31BB5" />
							<stop offset="0.5557" stopColor="#AB30BF" />
							<stop offset="0.9791" stopColor="#8652CE" />
							<stop offset="1" stopColor="#8454CF" />
						</linearGradient>
						<linearGradient
							id="iDSmallAnimationItem3"
							x1="48.7762"
							y1="28.0465"
							x2="104.002"
							y2="28.0465"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FD7275" />
							<stop offset="1" stopColor="#E53E90" />
						</linearGradient>
						<linearGradient
							id="iDSmallAnimationItem4"
							x1="48.7762"
							y1="78.563"
							x2="103.133"
							y2="78.563"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#66E0FC" />
							<stop offset="0.0715242" stopColor="#63D9F9" />
							<stop offset="0.4552" stopColor="#55B9ED" />
							<stop offset="0.7784" stopColor="#4CA6E6" />
							<stop offset="1" stopColor="#499FE3" />
						</linearGradient>
					</defs>
				</svg>
			</WrapperLogo>
		</>
	);
};

export default LogoSmallNav;
